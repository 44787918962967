import { call, put, takeEvery, fork } from "redux-saga/effects";
import { CONST_VALUES } from "../../constants/constants";
import * as multiSarePermissionsService from "../../services/multiSharePermissionsService";
import * as permissionService from "../../services/permissionService";
import {
  CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING,
  postMultipleAssetPermissionsFulfilled,
  postMultipleAssetPermissionsRejected,
  CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING
} from "../actions/multiSharePermissionsAction";

import getResourceError from "./helpers/getResourceError";
import { getRestrictedAssets } from "./assetSaga";
import { notificationSend } from "../actions/notificationActions";

export function* postMultipleAssetPermissions(action) {
  const { payload, panelType, restrictedAccess, page } = action.payload;
  try {
    let response = {};
    if (panelType === CONST_VALUES.edit) {
      response = yield call(permissionService.putPermissions, payload);
    } else {
      response = yield call(
        multiSarePermissionsService.multiSharePermissions,
        payload
      );
      if (page === "approve") {
        yield call(
          showNotification,
          "Access to the asset(s) was approved for the user(s).",
          "success"
        );
      }
    }
    yield put(postMultipleAssetPermissionsFulfilled(response));
    if (response?.status === "success" && restrictedAccess) {
      yield fork(getRestrictedAssets);
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "PERMISSIONS",
      "Oops. An error occurred when updating the permissions."
    );
    yield put(postMultipleAssetPermissionsRejected(resourceError));
  }
}

export function* postMultipleAssetPermissionsReset(action) {
  const { payload } = action.payload;
  yield put(postMultipleAssetPermissionsFulfilled(payload));
}

function* watchMultipleAssetPermissions() {
  yield takeEvery(
    CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING,
    postMultipleAssetPermissions
  );
}
function* watchMultipleAssetPermissionsReset() {
  yield takeEvery(
    CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING,
    postMultipleAssetPermissionsReset
  );
}
function* showNotification(message, responseStatus) {
  yield put(
    notificationSend({
      id: new Date(),
      label: message,
      variant: responseStatus,
      dismissAfter: 5000
    })
  );
}

export default [
  watchMultipleAssetPermissions,
  watchMultipleAssetPermissionsReset
];
