import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  DoubleError,
  WarningIcon,
  GoodIcon,
  UnknownIcon,
  SupportCaseIcon
} from "../components/common/icons";
import styled from "styled-components";
import { COLORS } from "../theme/helpers/colors";

const Critical = styled(DoubleError)`
  ${p => p.theme?.Critical?.style}
`;
const Warning = styled(WarningIcon)`
  ${p => p.theme?.Warning?.style}
`;
const Attention = styled(WarningIcon)`
  ${p => p.theme?.Attention?.style}
`;
const Good = styled(GoodIcon)`
  ${p => p.theme?.Good?.style}
`;
const Unknown = styled(UnknownIcon)`
  ${p => p.theme?.Unknown?.style}
`;
const Settings = styled(SettingsIcon)`
  ${p => p.theme?.SettingsIcon?.style}
`;

const SupportCase = styled(SupportCaseIcon)`
  ${p => p.theme?.SettingsIcon?.style}
`;

export const STATUS_VALUES = {
  CRITICAL: 4,
  WARNING: 3,
  ATTENTION: 2,
  UNKNOWN: 1,
  GOOD: 0
};

export const STATUS_COLORS = {
  CRITICAL: COLORS.red,
  WARNING: COLORS.yellow,
  ATTENTION: COLORS.yellow,
  GOOD: COLORS.Green,
  UNKNOWN: COLORS.silver,
  SUPPORTCASE: COLORS.orange,
  ERRORS: COLORS.red,
  WARNINGS: COLORS.mediumOrange,
  BLOCKED: COLORS.silver,
  OKAY: COLORS.limeGreen,
  "TO BE FIXED": COLORS.mediumOrange
};

export const GRAPH_COLORS = {
  HIGH: "#E5A13C",
  AVG: "#6AB1DD",
  LOW: "#B13A70",
  GOOD: "#D7E6CF",
  WARNING: "#fed8b1",
  CRITICAL: "#F4D3D4",
  NORMAL: "#D8E6F1"
};

export const AMBIENT_GRAPH_COLORS = {
  CRITICAL: "#C62828",
  WARNING: "#DB6B22",
  GOOD: "#59941B",
  HIGH: "#EBB07C",
  AVG: "#B3C6EE",
  LOW: "#8EC6B6"
};

export const STATUS_ICONS = {
  CRITICAL: <Critical />,
  ATTENTION: <Attention />,
  WARNING: <Warning />,
  GOOD: <Good />,
  UNKNOWN: <Settings />,
  ERRORS: <Critical />,
  "TO BE FIXED": <Attention />,
  OKAY: <Good />,
  BLOCKED: <Unknown />,
  SUPPORTCASE: <SupportCaseIcon />
};

export const HARDWARE_STATUS_VALUES = {
  ERROR: 5,
  WARNING: 4,
  "TO BE FIXED": 3,
  BLOCKED: 2,
  OKAY: 1
};

export const ALERT_STATUS_VALUES = {
  CRITICAL: 5,
  WARNING: 4,
  ATTENTION: 3,
  SUPPORTCASE: 2,
  UNKNOWN: 1
};

export const INTERNAL_DRIVE_SUMMERY_COLORS = {
  SSD: "#95AFE8",
  FMD: "#E89E5D",
  NVME: "#73BAA5",
  QLC: "#D17A7C",
  HDD: "#6F749F"
};
