import createWithActionCreator from "./helpers/createWithActionCreator";

export const CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING =
  "CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING";
export const CREATE_MULTIPLE_ASSET_PERMISSIONS_REJECTED =
  "CREATE_MULTIPLE_ASSET_PERMISSIONS_REJECTED";
export const CREATE_MULTIPLE_ASSET_PERMISSIONS_FULFILLED =
  "CREATE_MULTIPLE_ASSET_PERMISSIONS_FULFILLED";

export const postMultipleAssetPermissionsPending = (
  payload,
  panelType,
  restrictedAccess,
  page
) => ({
  type: CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING,
  payload: { payload, panelType, restrictedAccess, page }
});

export const postMultipleAssetPermissionsRejected = error => ({
  type: CREATE_MULTIPLE_ASSET_PERMISSIONS_REJECTED,
  payload: error
});

export const postMultipleAssetPermissionsFulfilled = payload => {
  return {
    type: CREATE_MULTIPLE_ASSET_PERMISSIONS_FULFILLED,
    payload
  };
};

export const withPostMultipleAssetPermissions = createWithActionCreator(
  "postMultipleAssetPermissions",
  postMultipleAssetPermissionsPending
);

export const CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING =
  "CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING";

export const postMultipleAssetPermissionsResetPending = payload => ({
  type: CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING,
  payload: { payload }
});

export const withPostMultipleAssetPermissionsReset = createWithActionCreator(
  "postMultipleAssetPermissionsReset",
  postMultipleAssetPermissionsResetPending
);
